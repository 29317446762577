@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	padding: 0;
	margin: 0;
	font-family: 'Montserrat', 'Syncopate', sans-serif;
	scrollbar-width: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
	width: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}
a.active {
	border: none;
	outline: none;
}
a:focus-visible {
	border: none;
	outline: none;
	color: inherit;
}
a:hover {
	color: inherit;
}
/* no active */
.cCICrW a {
}
/* actibe */
.eBtXQX a {
	color: #19405a;
}
.eBtXQX a:hover {
	color: #19405a;
	font-weight: normal !important;
}
.gWmnli {
	max-width: 70%;
}
@media screen and (max-width: 500px) {
	.eLkIqO {
		display: none;
	}
}

.audio-active {
	display: block!important;
}
.audio-stop{
	display: none!important;
}
.swiper-button-next::after, .swiper-button-prev::after {
	color: white;
}
@media screen and (max-width: 640px) {
    .swiper-button-next, .swiper-button-prev {
        display: none;
    }
}

.swiper {
	padding-bottom: 40px;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom, .swiper-pagination-fraction {
	bottom: 13px;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: white;
}
.swiper-button-next, .swiper-button-prev {
    top: var(--swiper-navigation-top-offset,42%) !important;
}

.swiper-wrapper {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

/* .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: red;
} */

.modalPopup .swiper-pagination-bullet.swiper-pagination-bullet-active
{
    background-color: #63b0e0 !important;
}

.modalPopup .swiper-button-next::after,
.modalPopup .swiper-button-prev::after {
    color: #63b0e0 !important;
}

.modalPopup.swiper {
    padding-bottom: 0px;
}

.modalPopup .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalPopup .swiper-horizontal > .swiper-pagination-bullets, 
.modalPopup .swiper-pagination-bullets.swiper-pagination-horizontal, 
.modalPopup .swiper-pagination-custom, 
.modalPopup .swiper-pagination-fraction {
    bottom: 0px;
}

.modalPopup .swiper-slide img {
    object-fit: contain;
    width: 80%;
}

@media screen and (max-width: 1024px) {
    .modalPopup .swiper-slide img {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .modalPopup .swiper-slide img {
        width: 100%;
    }

    .modalPopup .swiper-button-prev, .swiper-button-next {
        display: none;
    }

    .modalPopup.swiper {
        padding-bottom: 25px;
    }
}
